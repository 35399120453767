import React, { useEffect } from 'react';

const VoiceflowWidget = ({ isActive }) => {
  useEffect(() => {
    // Function to initialize the widget
    const initializeWidget = () => {
      const uniqueContainerId = `voiceflow-chat-${Date.now()}`;

      // Create a new container
      const container = document.createElement('div');
      container.id = uniqueContainerId;
      document.body.appendChild(container);

      // Create the script
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://cdn.voiceflow.com/widget/bundle.mjs';
      script.async = true;

      // Load the widget when the script finishes loading
      script.onload = () => {
        if (window.voiceflow?.chat?.load) {
          window.voiceflow.chat.load({
            verify: { projectID: '673e31d0f73ab4cc38a696ac' },
            url: 'https://general-runtime.voiceflow.com',
            versionID: 'production',
            settings: { containerID: uniqueContainerId },
          });
        }
      };

      // Append the script to the DOM
      document.body.appendChild(script);
    };

    // Function to cleanup the widget
    const cleanupWidget = () => {
      // Find and remove the widget container
      const voiceflowWidget = document.querySelector('[id^="voiceflow-chat"]');
      if (voiceflowWidget) {
        voiceflowWidget.remove();
      }

      // Remove the script element
      const script = document.querySelector('script[src="https://cdn.voiceflow.com/widget/bundle.mjs"]');
      if (script) {
        script.remove();
      }

      // Clear any lingering widget state
      if (window.voiceflow && window.voiceflow.chat) {
        delete window.voiceflow.chat;
      }
    };

    if (isActive) {
      // Initialize the widget
      initializeWidget();
    } else {
      // Clean up the widget
      cleanupWidget();
    }

    // Cleanup on unmount (optional, if required)
    return () => {
      cleanupWidget();
    };
  }, [isActive]);

  return null;
};

export default VoiceflowWidget;
