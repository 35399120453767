import React, {useState, useEffect, useContext, useCallback} from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import FilesCard from '../components/FilesCard';

const Files = () => {
    let [files, setFiles] = useState([])
    let {authTokens, logoutUser} = useContext(AuthContext)
    let navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL; 

    let getFiles = useCallback(async() =>{
        let response = await fetch(`${apiUrl}/api/files/`, {
            method:'GET',
            headers:{
                'Content-Type':'application/json',
                'Authorization':'Bearer ' + String(authTokens.access)
            }
        })
        let data = await response.json()

        if(response.status === 200){
            setFiles(data.files)
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
    }, [authTokens.access, logoutUser])

    useEffect(()=> {
        getFiles()
    }, [getFiles])


    let upload = async(e) =>{
        const formData = new FormData();
        const d = {'file_name': e.target.file_name.value, 'file': e.target.file.files[0]}
        for (const name in d){
            formData.append(name, d[name])
        }

        e.preventDefault()
        let response = await fetch(`${apiUrl}/api/files/`, {
            method:'POST',
            headers:{
                'Authorization':'Bearer ' + String(authTokens.access)
            },
            body: formData
        })
        let data = await response.json()
        if(response.status === 201){
            setFiles(files => [...files, data])
            document.getElementById('upload-form').reset()
        }else if(response.statusText === 'Unauthorized'){
            logoutUser()
        }
    }

    let deleteFile = async (id) =>{
        let response = await fetch(`${apiUrl}/api/files/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });

        if (response.status === 204 || response.status === 200) {
            console.log('Deleted');
            getFiles();
        } else if (response.status === 'Unauthorized'){
            logoutUser();
        }
    }

    return (
        <div>
            <form onSubmit={upload} id='upload-form' className='justify-center flex mt-4 ' >
                <input className='' type='text' id='file_name' placeholder='File name'/>
                <input className='rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 max-w-[250px]' type='file' id='file' />
                <button className='btn btn-primary'>Upload</button>
            </form>

            <ul className='flex flex-wrap justify-center mt-4'>
                {files.map(file => (
                    <li className='mb-3' key={file.id}>
                            <FilesCard id={file.id} name={file.file_name} deleteFile={deleteFile} file_type={file.file_type}/>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Files