import { NavLink } from "react-router-dom";
function FilesCard(props){
    return (
        <div className="h-36 min-w-[250px] max-w-[350px] m-2 py-8 px-8 max-w-sm bg-white rounded-xl shadow-lg space-y-2 sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                <img className="object-cover rounded-full h-[100px] w-[100px] block mx-auto h-24 rounded-full sm:mx-0 sm:shrink-0" src={props.file_type ==='audio' ? 'https://cdn.pixabay.com/photo/2017/03/08/21/18/audio-2127820_1280.png' : 'https://cdn.pixabay.com/photo/2019/04/24/21/55/cinema-4153289_1280.jpg'} alt="Video/Audio icon" />
                <div className="text-center space-y-2 sm:text-left">
                    <div className="space-y-0.5">
                    <p className="text-lg text-black font-semibold">
                        {props.name.length < 30 ? props.name : `${props.name.substring(0, 27)}...`}
                    </p>
        <NavLink to={`/files/${props.id}/`}>
                    <p className="text-slate-500 font-medium">
                        <button className='btn btn-success'> Open </button>
                    </p>
        </NavLink>
                    </div>

                </div>
            </div>

    ) 
}

export default FilesCard;