import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from './utils/PrivateRoute'
import { AuthProvider } from './context/AuthContext'

import HomePage from './pages/HomePage'
import RegisterPage from './pages/Register';
import LoginPage from './pages/LoginPage'
import Header from './components/Header'
import Files from './pages/Files';
import File from './pages/File';


function App() {
  return (
    <div className="App bg-gray-100">
      <BrowserRouter>
        <AuthProvider>
          <Header>
          < Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="" element={<PrivateRoute><HomePage /></PrivateRoute>} />
            <Route path="/files" element={<PrivateRoute><Files /></PrivateRoute>} />
            <Route path="/files/:id" element={<PrivateRoute><File /></PrivateRoute>} />
          </Routes>
          </Header>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
