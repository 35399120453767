import React from 'react';


function HomePage(){

    return(
        <>
            <p>Hello there, this is home page</p>
        </>
    )
}

export default HomePage