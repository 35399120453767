import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import VoiceflowWidget from '../components/VoiceflowWidget'
import { useParams, useNavigate } from 'react-router-dom';

const File = () => {
    let { id } = useParams();
    let navigate = useNavigate();
    let [file, setFile] = useState(null);
    let { authTokens, logoutUser } = useContext(AuthContext);
    const [isWidgetActive, setIsWidgetActive] = useState(true); // Default to ON
    const apiUrl = process.env.REACT_APP_API_URL; 

    useEffect(() => {
        const getFile = async () => {
            let response = await fetch(`${apiUrl}/api/files/${id}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + String(authTokens.access)
                }
            });
            let data = await response.json();

            if (response.status === 200) {
                setFile(data);
            } else if (response.statusText === 'Unauthorized') {
                logoutUser();
            }
        };

        getFile();
    }, [id, authTokens.access, logoutUser]);

    let createTranscript = async () => {
        let response = await fetch (`${apiUrl}/api/files/transcribe/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });
        
        let data = await response.json();

        if (response.status === 200){
            setFile(prevFile => {
                return {
                    ...prevFile,
                    file: {
                        ...prevFile.file,
                        transcript: data.transcript
                    }
                }
            })
        } else if (response.statusText === 'Unauthorized'){
            logoutUser();
        }
    }

    let createSummary = async () => {
        let response = await fetch (`${apiUrl}/api/files/summary/${id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });

        let data = await response.json();

        if (response.status === 200){
            setFile(prevFile => {
                return {
                    ...prevFile,
                    file: {
                        ...prevFile.file,
                        summary: data.summary
                    }
                }
            })
        } else if (response.statusText === 'Unauthorized'){
            logoutUser();
        }
    }

    let deleteFile = async () => {
        let response = await fetch(`${apiUrl}/api/files/${id}/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + String(authTokens.access)
            }
        });

        if (response.status === 200 || response.status === 204){
            navigate('/files');
        } else if (response.status === 'Unauthorized'){
            logoutUser()
        }

    }

    const handleBack = () => {
        setIsWidgetActive(false);
        setTimeout(() => {
            navigate('/files');
          }, 200);
      };

    const downloadFile = async () => {
        const fileUrl = `${apiUrl}/api/files/download/${id}/`;

        try {
          const response = await fetch(fileUrl, {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + String(authTokens.access), 
            }
          });
    
          if (!response.ok) {
            throw new Error('File download failed.');
          }
    
          const blob = await response.blob();
    
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = file.file.file_name;  
          document.body.appendChild(a);
          a.click();
    
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } catch (error) {
          console.error('Error during file download:', error);
        }
      };

    if (!file) return <p>Loading...</p>;

    const paragraphs = file.file.transcript.split(/[.?!]\s+/);
        const summary_paragraphs = file.file.summary.split(/[.?!]\s+/);

    return (
        <>
            <div className='flex justify-start relative' >
                <div className=''>
                    <button 
                        className="h-10 rounded-full py-2 px-3 uppercase text-xs font-bold cursor-pointer tracking-wider text-primary border-primary md:border-2 mr-2"
                        onClick={handleBack}
                        > 
                            Back 
                    </button>
                </div>

                <div className='absolute right-20'>
                        <button 
                        className="mr-2 px-5 py-2.5 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"                   
                        onClick={downloadFile}
                        >
                                Download File
                        </button>
                        <button 
                            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                            onClick={deleteFile}
                            >
                                Delete File
                        </button>
                </div>
            </div>

            <div className="mt-4 bg-white shadow-lg rounded-lg w-full max-w-4xl p-6 md:p-8 overflow-auto ">
                <div className='relative'> 

                    <h1 className="text-gray-800 text-2xl font-bold mb-0"> {file.file.file_name.charAt(0).toUpperCase() + file.file.file_name.slice(1) } Transcript </h1>
                    <p className='font-bold'> {file.file.file_type}</p>

                    <button 
                        className="absolute top-0 right-4 px-5 py-2.5 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"                                       
                        onClick={createTranscript}
                        >
                            Create transcript
                    </button>
                </div>

                <div className="text-gray-700 leading-relaxed space-y-4 max-h-96 overflow-y-scroll p-4">
                    
                    {paragraphs.map((para, index) => (
                        <p key={index} className="mb-3 ">
                            {para || 'Transcript not created'}.
                        </p>
                    ))}

                </div>
            </div>
        
            {
                file.file.transcript ?  
            <>                    
            <div className="mt-4 bg-white shadow-lg rounded-lg w-full max-w-4xl p-6 md:p-8 overflow-auto">
                <div className='relative'> 
                    <h1 className="text-gray-800 text-2xl font-bold mb-0"> {file.file.file_name.charAt(0).toUpperCase() + file.file.file_name.slice(1) } Summary </h1>
                    <button 
                        className="absolute top-0 right-4 px-5 py-2.5 text-sm font-medium text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"                                       
                        onClick={createSummary} 
                        disabled={!file.file.transcript}
                        >
                        Create summary
                    </button>
                </div>
                <div className="text-gray-700 leading-relaxed space-y-4 max-h-96 overflow-y-scroll relative p-4">
                    
                    {summary_paragraphs.map((para, index) => (
                        <p key={index} className="mb-4">
                            {para || 'Summary not created'}.
                        </p>
                    ))}
                </div>
            </div> 

            <VoiceflowWidget isActive={isWidgetActive} />
            </>
            : 
            <p>  </p>
            }

        </>
    );
};

export default File;
